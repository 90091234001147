import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MdHome, MdPerson, MdAddCircleOutline, MdBusinessCenter, MdViewList } from 'react-icons/md';

const Tabbar = (props) => {
  const grey = '#C3C8FF';
  const blue = '#4ED9EC';
  const location = useLocation();
  const [active, setActive] = useState(location.pathname);

  const handleClick = (path) => {
    setActive(path);
  };

  return (
      <div className="tabbar" style={{ display: props.show }}>
        <Link className='linkto' onClick={() => handleClick('/home')} to="/home">
          <MdHome width={24} color={active === '/home' ? blue : grey} />
          <small style={{color: active === '/home' ? blue : grey}}>Home</small>
        </Link>
        <Link className='linkto' to="https://app.slidebean.com/p/z8nbfsg9bo" target="_blank">
          <MdPerson width={24} color={active === '/profile' ? blue : grey} />
          <small style={{color: active === '/profile' ? blue : grey}}>Profile</small>
        </Link>
        <Link className='linkto' onClick={() => handleClick('/project/new')} to="/project/new">
          <MdAddCircleOutline width={24} color={active === '/project/new' ? blue : grey} />
          <small style={{color: active === '/project/new' ? blue : grey}}>Project</small>
        </Link>
        <Link className='linkto' onClick={() => handleClick('/user/projects')} to="/user/projects">
          <MdBusinessCenter width={24} color={active === '/user/projects' ? blue : grey} />
          <small style={{color: active === '/user/projects' ? blue : grey}}>Joined</small>
        </Link>
        <Link className='linkto' onClick={() => handleClick('/projects')} to="/projects">
          <MdViewList width={24} color={active === '/projects' ? blue : grey} />
          <small style={{color: active === '/projects' ? blue : grey}}>Projects</small>
        </Link>
    </div>
  )
}

export default Tabbar
