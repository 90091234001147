import React from 'react'
import Appbar from '../components/bars/Appbar'
import Tabbar from '../components/bars/Tabbar'
import ProjectCard from '../components/lists/ProjectCard'

import data from '../data/projects'

function Home() {
  return (
    <div className='mw'>
      <div className="screen">
        <Appbar show='flex' />
        <h1 className="title form">Home</h1>
        {data.map((el) =>
          <ProjectCard
            key={el.id}
            logo={el.logo}
            title={el.title}
            desc={el.desc.substring(0,10)}
            status={el.status}
            members={el.members}
            color={el.color}
          />
        )}
        <Tabbar show='flex' />
      </div>
    </div>
  )
}

export {Home}
