import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Login } from './Login'

import logo from '../assets/svg/logo/splash-logo.svg'

function Splash() {
  const navigate = useNavigate()

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate('/login')
    }, 3000)

    return () => clearTimeout(timeout)
  }, [navigate])

  return (
    <>
    <div className="hidden"><Login /></div>
    <div className='mw'>
      <div className='screen splash'>
        <h1 className='title'>Welcome to Multiwork</h1>
        <img src={logo} className='logo-splash' alt='MultiWork Logo' />
      </div>
    </div>
    </>
  )
}

export { Splash }
