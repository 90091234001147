import React from 'react'
import { Link } from 'react-router-dom'

const ProjectCard = (props) => {
  return (
    <div className="card">
      <div className="block">
          <div className="flex jcfs">
            <img src={props.logo} width={24} height={24} alt={props.title} className="plogo" />
            <div className="block">
              <h3 className="name">{props.title}</h3>
              <span className="desc">{props.desc}</span>
            </div>
          </div>
          <div className="flex">
            <small className="status" style={{color: props.color}}>{props.status}</small>
            <small className="team">Members: <span>{props.members}</span></small>
          </div>
      </div>
      <Link to='/joined' className="join">Join</Link>
    </div>
  )
}

export default ProjectCard
