import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

function FormForgot() {
  const [showMessage, setShowMessage] = useState(false)
  const navigate = useNavigate()

  const handleSubmit = (e) => {
    e.preventDefault()
    setShowMessage(true)
    setTimeout(() => {
      navigate('/login')
    }, 2000)
  }

  return (
    <form onSubmit={handleSubmit} className='form'>
      <h1 className="title form">Password Recovery</h1>
      <input type='email' placeholder='Email' />
      <input type='submit' value='Recover Password' />
      {showMessage && (
        <p className="title form mini">Check your email for instructions on how to recover your password</p>
      )}
      <Link to='/login' className="title form medium">Back to Login</Link>
    </form>
  )
}

export { FormForgot }
