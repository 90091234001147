import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

function FormRegister() {

  const navigate = useNavigate()

  return (
    <>
    <form onSubmit={() => navigate('/onboarding')} className='form'>
      <h1 className="title form">Sign Up</h1>
      <input type='email' placeholder='Email' />
      <input type='password' placeholder='Password' />
      <input type="submit" value='Sign Up' />
    </form>
    <Link to='/login' className="title form medium">Already Have an Account</Link>
    </>
  )
}

export {FormRegister}
