import React from 'react'
import {FormLogin} from '../components/forms/FormLogin'


function Login() {
  return (
    <div className='mw'>
      <div className="screen">
        <FormLogin />
      </div>
    </div>
  )
}

export {Login}
