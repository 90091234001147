import React from 'react'
import {FormRegister} from '../components/forms/FormRegister'


function Signup() {
  return (
    <div className='mw'>
      <div className="screen">
        <FormRegister />
      </div>
    </div>
  )
}

export {Signup}
