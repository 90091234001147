import React from 'react'
import Appbar from '../components/bars/Appbar'
import Tabbar from '../components/bars/Tabbar'

function Profile() {
  return (
    <div className='mw'>
      <div className="screen">
        <Appbar show='flex' />
        <h1 className="title form">Profile</h1>
        <Tabbar show='flex' />
      </div>
    </div>
  )
}

export {Profile}
