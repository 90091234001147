import React from 'react'
import { Link } from 'react-router-dom'

import pic1 from '../assets/svg/onboarding/p001.svg'
import pic2 from '../assets/svg/onboarding/p002.svg'
import pic3 from '../assets/svg/onboarding/p003.svg'

function Onboarding() {

  return (
    <div className='mw'>
      <div className="screen onboarding">
        <h1 className="title fz-14 w-60 ma">Want to create some wonderful in our  multiverse?</h1>
        <div className="w-100 ma">
          <Link to='/home' className="flex jcsb aic">
            <p className="c-blue tc fz-12 w-25 ml-20 w600">To create a grand project</p>
            <img src={pic1} alt='Be an Author' className='w-75 ml-20' />
          </Link>
          <Link to='/home' className="flex jcsa aic">
            <img src={pic2} alt='Be a Part' className='w-75 mr-20' />
            <p className="c-pink tc fz-12 w-25 mr-20 w600">Be a part of grand project</p>
          </Link>
          <Link to='/home' className="flex jcsa aic">
            <p className="c-golden tc fz-12 w-25 ml-20 w600">I light the stars in the sky</p>
            <img src={pic3} alt='Be a Star' className='w-75 ml-20' />
          </Link>
        </div>
        <div className="s-20"></div>
      </div>
    </div>
  )
}

export {Onboarding}
