import React from 'react'
import { useNavigate } from 'react-router-dom'

function NewProject() {

  const navigate = useNavigate()

  return (
    <>
      <form onSubmit={() => navigate('/projects')} className='form'>
        <h1 className="title form">Create New Project</h1>
        <input type='email' placeholder='Project Name' />
        <textarea placeholder='Description' />
        <input type="submit" value='Save' />
      </form>
    </>
  )
}

export {NewProject}
