import React from 'react'
import Appbar from '../components/bars/Appbar'
import Tabbar from '../components/bars/Tabbar'
import {NewProject} from '../components/forms/NewProject'


function CreateProject() {
  return (
    <div className='mw'>
      <div className="screen">
        <Appbar show='flex' />
        <NewProject />
        <Tabbar show='flex' />
      </div>
    </div>
  )
}

export {CreateProject}
