import React from 'react'
import Appbar from '../components/bars/Appbar'
import Tabbar from '../components/bars/Tabbar'

function Users() {
  return (
    <div className='mw'>
      <div className="screen">
        <Appbar show='flex' />
        <h1 className="title">Users</h1>
        <Tabbar show='flex' />
      </div>
    </div>
  )
}

export {Users}
