import React from 'react'
import {FormForgot} from '../components/forms/FormForgot'

function Forgot() {
  return (
    <div className='mw'>
      <div className="screen">
        <FormForgot />
      </div>
    </div>
  )
}

export {Forgot}
