import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './styles/custom.sass'

import {Splash} from './screens/Splash'
import {Login} from './screens/Login'
import {Forgot} from './screens/Forgot'
import {Home} from './screens/Home'
import {Signup} from './screens/Signup'
import {Onboarding} from './screens/Onboarding'
import {Skills} from './screens/Skills'
import {Users} from './screens/Users'
import {Profile} from './screens/Profile'
import {Projects} from './screens/Projects'
import {Joined} from './screens/Joined'
import {Project} from './screens/Project'
import {CreateProject} from './screens/CreateProject'
import {Teams} from './screens/Teams'
import {Team} from './screens/Team'
import {Settings} from './screens/Settings'
import {Messages} from './screens/Messages'
import {NotFound} from './screens/NotFound'

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Splash />} />
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/skills" element={<Skills />} />
        <Route path="/users" element={<Users />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/user/projects" element={<Joined />} />
        <Route path="/project" element={<Project />} />
        <Route path="/project/new" element={<CreateProject />} />
        <Route path="/teams" element={<Teams />} />
        <Route path="/team" element={<Team />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/messages" element={<Messages />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
