import React from 'react'
import Appbar from '../components/bars/Appbar'
import Tabbar from '../components/bars/Tabbar'

function NotFound() {
  return (
    <div className='mw'>
      <div className="screen not">
        <Appbar show='flex' />
        <div className="s-100"></div>
        <h1 className="title form c-pink">404 - Error</h1>
        <h4 className="title mini w-80 ma">
          Oops! Looks like this page has been abducted by aliens! Don't worry, we're on the case to bring it back. In the meantime, feel free to explore the rest of our site and discover amazing things. And remember, even extraterrestrials need a good laugh sometimes!
        </h4>
        <Tabbar show='none' />
      </div>
    </div>
  )
}

export {NotFound}
